import { Link } from "gatsby"
import * as React from "react"
import logo from "../images/shared/logo.svg"
import "./NavBar.scss"

const pages = [
  "index",
  "au-coeur-de-greengo",
  "nos-offres-pro",
  "nous-trouver",
  "on-recrute",
  "contact",
] as const

type Pages = typeof pages[number]

interface NavBarProps {
  activePage: Pages
}

const NavBar: React.FunctionComponent<NavBarProps> = ({ activePage }) => {
  return (
    <header className="header background--electric-blue padding">
      <Link to="/" className="logo_container">
        <img src={logo} alt="logo GreenGo" className="logo" />
      </Link>
      <nav role="navigation" className="header__nav nav">
        <div id="desktop">{generateNavLink("desktop", activePage)}</div>

        <div id="mobile">
          <div id="menuToggle">
            <input type="checkbox" />
            <span />
            <span />
            <span />
            {generateNavLink("mobile", activePage)}
          </div>
        </div>
      </nav>
    </header>
  )
}

const generateNavLink = (platform: "mobile" | "desktop", activePage: Pages) => {
  return (
    <ul id={platform === "mobile" ? "menu" : ""} className="nav__items">
      {([
        ["au-coeur-de-greengo", "Au Coeur de GreenGo"],
        ["nos-offres-pro", "Nos Offres Partenaires"],
        ["nous-trouver", "Nous Trouver"],
      ] as const).map(v => getNavLink(v[0], v[1], activePage == v[0]))}
      <li className="nav__item">
        <a
          href="https://jobs.makesense.org/?query=la%20consigne%20greengo&page=0&hitsPerPage=20&facets=%5B%22%2a%22,%22professions%22,%22contracts%22,%22sectors%22,%22projectSize%22,%22remote%22,%22legalForm%22,%22languages%22%5D&facetFilters=%5B%5B%5D,%5B%5D,%5B%5D,%5B%5D,%5B%5D,%5B%5D,%5B%5D%5D&restrictIndices=prod_JOBS&filters=status%3Aactive"
          target="_blank"
          rel="noopener noreferrer"
          className={`link link--color-white nav__link`}
        >
          On Recrute&nbsp;!
        </a>
      </li>
      <li className="nav__item">
        <Link
          to="/contact"
          className={`button button--color-green nav__link contact ${
            activePage === "contact" ? "active" : ""
          }`}
        >
          Contact
        </Link>
      </li>
    </ul>
  )
}

const getNavLink = (link: Pages, content: string, active: boolean) => (
  <li key={link} className={`nav__item ${active ? "active" : ""}`}>
    <Link
      to={`/${link}`}
      className={`link link--color-white nav__link ${link} `}
    >
      {content}
    </Link>
  </li>
)

export default NavBar
