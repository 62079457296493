import { Link } from "gatsby"
import React from "react"
import apple from "../images/shared/download-appstore.png"
import google from "../images/shared/download-googleplay.png"
import "./Footer.scss"

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="footer background--pale-turquoise padding">
      <h2 className="heading heading--2 heading--svg heading--svg-left">
        Consigné, et plus si affinité !
      </h2>
      <section className="download">
        <a href="https://itunes.apple.com/fr/app/greengo/id1440472850?mt=8">
          <img src={apple} alt="appstore-img" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=fr.mygreengo.greengo">
          <img src={google} alt="playstore-img"/>
        </a>
      </section>

      <nav className="nav nav__footer">
        <ul>
          <li>
            <a href="https://www.facebook.com/laconsignegreengo/">FACEBOOK</a>
          </li>
          <li>
            <a href="https://www.instagram.com/laconsignegreengo">INSTAGRAM</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/mygreengo/about/">
              LINKEDIN
            </a>
          </li>
        </ul>
      </nav>

      <nav className="nav nav__footer">
        <ul>
          <li>
            <Link to="/contact">Contactez-nous</Link>
          </li>
          <li>
            <a href="/cgu.html">Conditions d'utilisation</a>
          </li>
          <li>
            <a href="/charte-de-confidentialite.html">
              Charte de confidentialité
            </a>
          </li>
          <li>
            <a href="/mention-legal.html">Mentions légales</a>
          </li>
          <li>
            <a href="/sitemap.xml">Arborescence site</a>
          </li>
        </ul>
      </nav>
      <p className="copyright">Mygreengo 2020 all rights reserved</p>
    </footer>
  )
}

export default Footer
